var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('genericDetails',{attrs:{"id":_vm.gameID,"itemName":"Game","redirectBack":("/developer/domain/" + _vm.domainID),"isNew":_vm.newGame,"createNew":_vm.createGame,"deleteOld":_vm.deleteGame}},[_c('template',{slot:"form"},[_c('v-text-field',{attrs:{"label":"Domain","disabled":""},model:{value:(_vm.domainID),callback:function ($$v) {_vm.domainID=$$v},expression:"domainID"}}),_c('v-text-field',{attrs:{"label":"Game ID","disabled":!_vm.newGame,"rules":[_vm.rules.gameID]},model:{value:(_vm.gameID),callback:function ($$v) {_vm.gameID=$$v},expression:"gameID"}}),_c('v-textarea',{attrs:{"label":"Description","disabled":!_vm.newGame,"rows":"1"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],2),(!_vm.newGame)?_c('genericTable',{attrs:{"itemName":"Client","headers":_vm.clientHeaders,"getItemsURL":("/admin/developer/game/" + _vm.gameID + "/clients"),"redirectTemplate":'/developer/domain/' +
      _vm.domainID +
      '/game/' +
      _vm.gameID +
      '/client/${item.clientID}',"createRedirect":("/developer/domain/" + _vm.domainID + "/game/" + _vm.gameID + "/createclient"),"canCreate":!_vm.newGame,"displayProperty":"tag"}}):_vm._e(),(!_vm.newGame)?_c('thirdPartyClientTable',{attrs:{"itemName":"Third-Party Identity Provider","headers":_vm.thirdPartyHeaders,"getItemsURL":("/admin/developer/game/" + _vm.gameID + "/thirdPartyClients"),"createRedirect":("/developer/domain/" + _vm.domainID + "/game/" + _vm.gameID + "/createThirdParty"),"redirectTemplate":'/developer/domain/' +
      _vm.domainID +
      '/game/' +
      _vm.gameID +
      '/thirdPartyClient/${item.identityProvider}',"canCreate":!_vm.newGame,"displayProperty":"tag"}}):_vm._e(),(!_vm.newGame)?_c('parentalConsentProviderTable',{attrs:{"itemName":"Parental Consent Provider","headers":_vm.parentalConsentProviderHeaders,"getItemsURL":("/admin/developer/game/" + _vm.gameID + "/parental-consent-providers"),"createRedirect":("/developer/domain/" + _vm.domainID + "/game/" + _vm.gameID + "/create-parental-consent-provider"),"redirectTemplate":'/developer/domain/' +
      _vm.domainID +
      '/game/' +
      _vm.gameID +
      '/parental-consent-provider/${item.parentalConsentProvider}',"canCreate":!_vm.newGame,"displayProperty":"tag"}}):_vm._e(),(!_vm.newGame)?_c('genericTable',{key:_vm.componentKey,attrs:{"itemName":"Role","headers":_vm.roleHeaders,"getItemsURL":("/admin/accounts/game/" + _vm.gameID + "/roles"),"redirectTemplate":"/developer/domain/${item.domainID}/game/${item.gameID}/role/${item.roleID}","createRedirect":("/developer/domain/" + _vm.domainID + "/game/" + _vm.gameID + "/createrole"),"canCreate":!_vm.newGame}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }